import React from 'react';
import ContactPage from '../components/ContactPage';

const Contact = () => {
    return (
        <div className='contact'>
            <ContactPage />
        </div>
    );
};

export default Contact;